(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/divider/assets/javascripts/index.js') >= 0) return;  svs.modules.push('/modules/divider/assets/javascripts/index.js');

'use strict';

svs = svs || {};
svs.content = svs.content || {};
svs.content.divider = svs.content.divider || {};
svs.content.divider.link = e => {
  e.preventDefault();
  const url = e.currentTarget.href;
  const target = e.currentTarget.target;
  trackClick(e.currentTarget, () => {
    if (target && target === '_blank') {
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }
  });
};
function trackClick(el, callback) {
  const $el = $(el);
  const track = $el.data('track');
  const areaName = $el.data('areaName') || 'unknown';
  if (track && svs.components.analytics.trackPromoClick) {
    svs.components.analytics.trackPromoClick({
      id: track,
      name: track,
      position: areaName
    }, callback);
  } else {
    callback();
  }
}
svs.content.divider.script = new svs.content.divider.V3();
svs.content.divider.script.init();
const links = document.querySelectorAll('.js-divider-link');
links.forEach(link => {
  new svs.ui.RippleEffect(link, () => {});
  link.addEventListener('click', svs.content.divider.link);
});

 })(window);