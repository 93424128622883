(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/divider/views/v1.js') >= 0) return;  svs.modules.push('/modules/divider/views/v1.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.divider=_svs.divider||{};
_svs.divider.templates=_svs.divider.templates||{};
svs.divider.templates.v1 = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isMobile","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":10,"column":21}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"brands",{"name":"ui","hash":{"square":true,"size":100,"brand":depth0,"class":"divider-v1-brand divider-v1-brand-ghost"},"data":data,"loc":{"start":{"line":7,"column":10},"end":{"line":7,"column":105}}}))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"brands",{"name":"ui","hash":{"square":true,"brand":depth0,"class":"divider-v1-brand divider-v1-brand-ghost"},"data":data,"loc":{"start":{"line":9,"column":10},"end":{"line":9,"column":96}}}))
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"w100 animate animate-show animate-delay-2s padding-xs-h padding-sm-h-0\">\n        <span class=\"f-400 fc-grey-600 f-bold hide-ao-sport-btn padding-xs-top-1\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"hideAOBtn") : stack1)) != null ? lookupProperty(stack1,"message") : stack1), depth0))
    + "</span>\n      </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"w100 animate animate-show animate-delay-2s padding-xs-h padding-sm-h-0\">\n        <span class=\"f-400 fc-grey-600 f-bold hide-ao-tur-btn padding-xs-top-1\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"hideAOBtn") : stack1)) != null ? lookupProperty(stack1,"message") : stack1), depth0))
    + "</span>\n      </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <img class=\"divider-v1-svg-ao\" src=\""
    + container.escapeExpression((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/images/content/divider/ao-sportcasino.svg",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":30,"column":48},"end":{"line":30,"column":108}}}))
    + "\" />\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"hideAOBtn") : stack1)) != null ? lookupProperty(stack1,"sport") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":38,"column":19}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"button",{"name":"ui","hash":{"size":300,"linkTitle":"SPORT & CASINO","disabled":true,"block":true,"class":"btn-sport"},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":14},"end":{"line":34,"column":29}}})) != null ? stack1 : "")
    + "\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "SPORT &\n                CASINO";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"button",{"name":"ui","hash":{"data-track":"divider-v1-btn-ao-sport","data-area-name":(depth0 != null ? lookupProperty(depth0,"areaName") : depth0),"size":300,"linkTitle":"SPORT & CASINO","inverted":true,"block":true,"href":((stack1 = (depth0 != null ? lookupProperty(depth0,"siteUrls") : depth0)) != null ? lookupProperty(stack1,"sport") : stack1),"class":"btn-sport js-divider-v1-btn js-divider-link"},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":14},"end":{"line":37,"column":31}}})) != null ? stack1 : "")
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "SPORT\n                & CASINO";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"brands",{"name":"ui","hash":{"square":true,"brand":depth0,"class":"divider-v1-brand divider-v1-brand-sport"},"data":data,"loc":{"start":{"line":43,"column":12},"end":{"line":43,"column":98}}}))
    + "\n";
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <img class=\"divider-v1-svg-ao\" src=\""
    + container.escapeExpression((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/images/content/divider/ao-tur.svg",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":58,"column":48},"end":{"line":58,"column":100}}}))
    + "\" />\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"hideAOBtn") : stack1)) != null ? lookupProperty(stack1,"tur") : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":60,"column":12},"end":{"line":64,"column":19}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"button",{"name":"ui","hash":{"size":300,"title":"TUR","inverted":true,"block":true,"disabled":true,"class":"btn-ao-tur btn-tur"},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":14},"end":{"line":61,"column":128}}})) != null ? stack1 : "")
    + "\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "TUR";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"button",{"name":"ui","hash":{"data-track":"divider-v1-btn-ao-tur","data-area-name":(depth0 != null ? lookupProperty(depth0,"areaName") : depth0),"size":300,"title":"TUR","inverted":true,"block":true,"href":((stack1 = (depth0 != null ? lookupProperty(depth0,"siteUrls") : depth0)) != null ? lookupProperty(stack1,"tur") : stack1),"class":"btn-ao-tur btn-tur js-divider-v1-btn js-divider-link"},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":14},"end":{"line":63,"column":225}}})) != null ? stack1 : "")
    + "\n";
},"29":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"brands",{"name":"ui","hash":{"square":true,"brand":depth0,"class":"divider-v1-brand divider-v1-brand-tur"},"data":data,"loc":{"start":{"line":69,"column":12},"end":{"line":69,"column":96}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"divider-v1-container f-content\">\n  <div class=\"divider-v1-center\">\n    <!-- GHOST -->\n    <div class=\"divider-v1-ghost-brands padding-top-1\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brands") : depth0)) != null ? lookupProperty(stack1,"scrambled") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":11,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n    <!-- SPORT -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"hideAOBtn") : stack1)) != null ? lookupProperty(stack1,"sport") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":19,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"hideAOBtn") : stack1)) != null ? lookupProperty(stack1,"tur") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":24,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"divider-v1-content divider-v1-content-sport\">\n      <div class=\"divider-v1-content-background\"></div>\n      <div class=\"divider-v1-content-holder\">\n        <div class=\"divider-v1-content-button\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"showLabels") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":29,"column":10},"end":{"line":39,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"divider-v1-content-brands divider-v1-content-brands-sport\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brands") : depth0)) != null ? lookupProperty(stack1,"sport") : stack1),{"name":"each","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":10},"end":{"line":44,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"divider-v1-content-text\">\n          Spel från Svenska Spel Sport & Casino AB\n        </div>\n      </div>\n    </div>\n\n    <!-- TUR -->\n    <div class=\"divider-v1-content divider-v1-content-tur\">\n      <div class=\"divider-v1-content-background\"></div>\n      <div class=\"divider-v1-content-holder \">\n        <div class=\"divider-v1-content-button\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"showLabels") : stack1),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":57,"column":10},"end":{"line":65,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"divider-v1-content-brands divider-v1-content-brands-tur\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brands") : depth0)) != null ? lookupProperty(stack1,"tur") : stack1),{"name":"each","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":10},"end":{"line":70,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"divider-v1-content-text\">\n          Spel från AB Svenska Spel\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});
Handlebars.partials['svs-divider-v1'] = svs.divider.templates.v1;
})(svs, Handlebars);


 })(window);