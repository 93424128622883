(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/divider/views/v2.js') >= 0) return;  svs.modules.push('/modules/divider/views/v2.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.divider=_svs.divider||{};
_svs.divider.templates=_svs.divider.templates||{};
svs.divider.templates.v2 = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"card/card-body",{"name":"ui","hash":{"class":"divider-v2-card-body bg-sport-100"},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":10},"end":{"line":40,"column":17}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"divider-v2-ao\">\n              <img src=\""
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,"/images/components/ao-logotype-soc/ao-soc.svg",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":22,"column":24},"end":{"line":22,"column":87}}}))
    + "\" alt=\"Svenska Spel Tur\" />\n            </div>\n            <div class=\"divider-v2-brands\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"svs-divider-brand"),depth0,{"name":"svs-divider-brand","hash":{"img":"/images/divider/brands/stryktipset"},"data":data,"indent":"              ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"svs-divider-brand"),depth0,{"name":"svs-divider-brand","hash":{"img":"/images/divider/brands/oddset"},"data":data,"indent":"              ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"svs-divider-brand"),depth0,{"name":"svs-divider-brand","hash":{"img":"/images/divider/brands/bingo"},"data":data,"indent":"              ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"svs-divider-brand"),depth0,{"name":"svs-divider-brand","hash":{"img":"/images/divider/brands/travgalopp"},"data":data,"indent":"              ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"svs-divider-brand"),depth0,{"name":"svs-divider-brand","hash":{"img":"/images/divider/brands/casino"},"data":data,"indent":"              ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"divider-v2-text f-medium\">\n              <span class=\"divider-v2-text-animate divider-v2-text-line-1\">Sport-</span>\n              <span class=\"divider-v2-text-animate divider-v2-text-line-2\">och</span>\n              <span class=\"divider-v2-text-animate divider-v2-text-line-3\">casinospel</span>\n              "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"icon",{"name":"ui","hash":{"class":"margin-xs-top-1 margin-md-top-2 divider-v2-icon-arrow","icon":"arrow-right"},"data":data,"loc":{"start":{"line":35,"column":14},"end":{"line":35,"column":108}}}))
    + "\n            </div>\n            <div class=\"divider-v2-ao-text\">\n              <p class=\"divider-v2-text-animate \">Spel från Svenska Spel Sport & Casino AB</p>\n            </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"card/card-body",{"name":"ui","hash":{"class":"divider-v2-card-body bg-tur-100"},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":12},"end":{"line":71,"column":19}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"divider-v2-ao\">\n                <img src=\""
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,"/images/components/ao-logotype-tur/ao-tur.svg",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":51,"column":26},"end":{"line":51,"column":89}}}))
    + "\" alt=\"Svenska Spel Tur\" />\n              </div>\n              <div class=\"divider-v2-brands\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"svs-divider-brand"),depth0,{"name":"svs-divider-brand","hash":{"img":"/images/divider/brands/lotto"},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"svs-divider-brand"),depth0,{"name":"svs-divider-brand","hash":{"img":"/images/divider/brands/triss"},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"svs-divider-brand"),depth0,{"name":"svs-divider-brand","hash":{"img":"/images/divider/brands/vikinglotto"},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"svs-divider-brand"),depth0,{"name":"svs-divider-brand","hash":{"img":"/images/divider/brands/eurojackpot"},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"svs-divider-brand"),depth0,{"name":"svs-divider-brand","hash":{"img":"/images/divider/brands/keno"},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "              </div>\n\n              <div class=\"divider-v2-text f-medium\">\n                <span class=\"divider-v2-text-animate divider-v2-text-line-1\">Lotter</span>\n                <span class=\"divider-v2-text-animate divider-v2-text-line-2\">och</span>\n                <span class=\"divider-v2-text-animate divider-v2-text-line-3\">nummerspel</span>\n\n                "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"icon",{"name":"ui","hash":{"class":"margin-xs-top-1 margin-md-top-2 divider-v2-icon-arrow","icon":"arrow-right"},"data":data,"loc":{"start":{"line":66,"column":16},"end":{"line":66,"column":110}}}))
    + "\n              </div>\n              <div class=\"divider-v2-ao-text \">\n                <p class=\"divider-v2-text-animate\">Spel från AB Svenska Spel</p>\n              </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<noscript>\n  <style>\n    .divider-v2-icon-arrow,\n    .divider-v2-text-animate,\n    .divider-v2-brand,\n    .divider-v2-tur,\n    .divider-v2-sport {\n      opacity: 1\n    }\n  </style>\n</noscript>\n<div class=\"padding-xs-bottom-1 padding-xs-h-1 padding-md-top-1 padding-md-h-2 padding-md-bottom-2 divider h-100\">\n  <div class=\"gutter-xs-1 gutter-md-2 h-100\">\n    <div class=\"grid-row h-100\">\n      <!-- SPORT & CASINO -->\n      <div class=\"col-xs-6 divider-v2-sport h-100\">\n        <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"siteUrls") : depth0)) != null ? lookupProperty(stack1,"sport") : stack1), depth0))
    + "\" data-area-name=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"areaName") || (depth0 != null ? lookupProperty(depth0,"areaName") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"areaName","hash":{},"data":data,"loc":{"start":{"line":18,"column":53},"end":{"line":18,"column":65}}}) : helper)))
    + "\" data-track=\"divider-v2-ao-sport\" class=\"divider-v2-link js-divider-link\">\n"
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias4).call(alias3,"card",{"name":"ui","hash":{"class":"divider-v2-card elevation-auto-raise"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":41,"column":15}}})) != null ? stack1 : "")
    + "        </a>\n      </div>\n      <!-- TUR -->\n\n      <div class=\"col-xs-6 divider-v2-tur \">\n        <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"siteUrls") : depth0)) != null ? lookupProperty(stack1,"tur") : stack1), depth0))
    + "\" data-area-name=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"areaName") || (depth0 != null ? lookupProperty(depth0,"areaName") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"areaName","hash":{},"data":data,"loc":{"start":{"line":47,"column":51},"end":{"line":47,"column":63}}}) : helper)))
    + "\" data-track=\"divider-v2-ao-tur\" class=\"divider-v2-link js-divider-link\">\n"
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias4).call(alias3,"card",{"name":"ui","hash":{"class":"divider-v2-card elevation-auto-raise"},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":10},"end":{"line":72,"column":17}}})) != null ? stack1 : "")
    + "        </a>\n      </div>\n    </div>\n  </div>\n</div>\n\n";
},"usePartial":true,"useData":true});
Handlebars.partials['svs-divider-v2'] = svs.divider.templates.v2;
})(svs, Handlebars);


 })(window);