(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/divider/views/brand.js') >= 0) return;  svs.modules.push('/modules/divider/views/brand.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.divider=_svs.divider||{};
_svs.divider.templates=_svs.divider.templates||{};
svs.divider.templates.brand = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<picture class=\"divider-v2-brand divider-v3-brand divider-no-js\">\n  <source\n    srcset=\""
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"img") : depth0),{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":31}}}))
    + "@3x.png 3x, "
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"img") : depth0),{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":3,"column":43},"end":{"line":3,"column":62}}}))
    + "@2x.png 2x, "
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"img") : depth0),{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":3,"column":74},"end":{"line":3,"column":93}}}))
    + "@2x.png\"\n    media=\"(max-width: 468px)\"\n  />\n  <source\n    srcset=\""
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"img") : depth0),{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":31}}}))
    + "@6x.png 3x, "
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"img") : depth0),{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":7,"column":43},"end":{"line":7,"column":62}}}))
    + "@5x.png 2x, "
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"img") : depth0),{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":7,"column":74},"end":{"line":7,"column":93}}}))
    + "@5x.png\"\n    media=\"(min-width: 468px)\"\n  />\n  <img\n    srcset=\""
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"img") : depth0),{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":31}}}))
    + "@5x.png 2x\"\n    src=\""
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"img") : depth0),{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":12,"column":9},"end":{"line":12,"column":28}}}))
    + "@5x.png\"\n    alt=\"3D produkt ikon\"\n  />\n</picture>\n";
},"useData":true});
Handlebars.partials['svs-divider-brand'] = svs.divider.templates.brand;
})(svs, Handlebars);


 })(window);